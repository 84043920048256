import { dialogHelper } from "../../dialog/DialogHelper";
import { excelHelper } from "../../services/excel.helper";
import JsZip from 'jszip';
import * as FileSaver from "file-saver";
import { MeekouConst } from "../../models/meekou.const";
import { AppConsts } from "../../shared/appconsts";

// Initialize the Office Add-in.
Office.onReady(() => {
    // If needed, Office.js is ready to be called
  });

  /**
   * create multiple sheets based on selected range
   * @param event command event
   */
  async function toSheets(event) {
    // Implement your custom code here. The following code is a simple Excel example.  
    dialogHelper.showLoading();
    try {
        await Excel.run(async (context) => {
            const selectedRange = context.workbook.getSelectedRange();
            selectedRange.load("values");
            await context.sync();
            let sheetNames: string[] = selectedRange.values.reduce(function (acc, row) {
                return acc.concat(row.map(String));
            }, []);
            sheetNames = Array.from(new Set(sheetNames));
            sheetNames = sheetNames.filter(function (sheet) {
                return sheet.toString().trim() !== "";
            });
            const existSheetNames = await excelHelper.getSheetNames();
            sheetNames.forEach(sheet => {
                if (!existSheetNames.includes(sheet)) {
                    const newSheet = context.workbook.worksheets.add(sheet);
                }
            });
        });
    } catch (error) {
        // Note: In a production add-in, notify the user through your add-in's UI.
        console.error(error);
    }    
    // Calling event.completed is required. event.completed lets the platform know that processing has completed.
    event.completed();
    dialogHelper.closeDialog();
  }

  /**
   * create multiple folders based on selected range value
   * @param event command event
   */
  async function createFolders(event) {
    // Implement your custom code here. The following code is a simple Excel example.  
    dialogHelper.showLoading();
    try {
        await Excel.run(async (context) => {
            const selectedRange = context.workbook.getSelectedRange();
            selectedRange.load("values");
            await context.sync();
            let folderNames: string[] = selectedRange.values.reduce(function (acc, row) {
                return acc.concat(row.map(String));
            }, []);
            folderNames = Array.from(new Set(folderNames));
            folderNames = folderNames.filter(function (sheet) {
                return sheet.toString().trim() !== "";
            });
            const existSheetNames = await excelHelper.getSheetNames();
            var zip = new JsZip();           
            let createFoldersCmd = "";
            folderNames.forEach(folderName => {
                zip.folder(folderName);
                createFoldersCmd += `MD "${folderName}"\n`
            });
            zip.file(`${AppConsts.Meekou}.bat`, createFoldersCmd);
            const zipContent = await zip.generateAsync({type:"blob"});
            FileSaver.saveAs(zipContent, `${AppConsts.Meekou}.zip`);
        });
    } catch (error) {
        // Note: In a production add-in, notify the user through your add-in's UI.
        console.error(error);
    }    
    // Calling event.completed is required. event.completed lets the platform know that processing has completed.
    event.completed();
    dialogHelper.closeDialog();
  }
  
  // You must register the function with the following line.
  Office.actions.associate("toSheets", toSheets);
  Office.actions.associate("createFolders", createFolders);