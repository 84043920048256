let dialog: Office.Dialog;
class DialogHelper {
    async showLoading(): Promise<void> {
        Office.context.ui.displayDialogAsync("https://localhost:3000/#/dialog/loading", {
            width: 18,
            height: 25,
        }, function (asyncResult: Office.AsyncResult<Office.Dialog>): void {
            dialog = asyncResult.value;
        });
    }
    async closeDialog(): Promise<void>{
        if(dialog){
            dialog.close();
        }        
    }
}

export const dialogHelper = new DialogHelper();