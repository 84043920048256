/* global Office, Excel */

import { AppConsts } from "../shared/appconsts";
import { DialogEventArg, DialogInput, DialogOutput } from "../shared/dialoginput";
import { CommonService } from "./common.service";
import { LoginByCodeInput } from "./meekouapi";
import { MovelColumnInput, MoveColumnOutput } from "./dialog.model";
import { TocInput, TocOutput } from "../models/TocDialog";
import { MergeWorkSheetsInput, MergeWorkSheetsOutput } from "../models/mergeWorkSheetsDialog";
import { ClearFormatInput, ClearFormatOutput } from "../models/clearFormat.models";
import { ExcelService } from "meekou-ts";
export var dialog: Office.Dialog;
let excelService: ExcelService = new ExcelService();

export async function showMoveColumnDialog(): Promise<void> {
  await Excel.run(async (context) => {
    const sheet: Excel.Worksheet = context.workbook.worksheets.getActiveWorksheet();
    var headerRange: Excel.Range = sheet.getUsedRange().getRow(0);
    headerRange.load("values");
    await context.sync();
    console.log(headerRange.values);
    let headerValues: any[] = headerRange.values[0];
    let moveColumnInput: MovelColumnInput = new MovelColumnInput({
      columnNames: headerValues,
    });
    let input: DialogInput<MovelColumnInput> = new DialogInput<MovelColumnInput>({
      name: AppConsts.MoveColumnDialog,
      width: 50,
      height: 50,
      body: moveColumnInput,
    });
    await showDialog(input);
  });
}

/**
 * dialog for login
 */
export async function showLoginDialog(): Promise<void> {
  let code: string = Math.floor(1000 + Math.random() * 9000).toString();
  let input: DialogInput<LoginByCodeInput> = new DialogInput<LoginByCodeInput>({
    name: AppConsts.LoginDialog,
    width: 20,
    height: 36,
    body: new LoginByCodeInput({
      inputCode: code,
    }),
  });
  await showDialog(input);
}

export async function showTocDialog(): Promise<void> {
  let sheetItems = await excelService.listItems();
  let input: DialogInput<TocInput> = new DialogInput<TocInput>({
    name: AppConsts.TocDialog,
    body: new TocInput({
      sheetItems: sheetItems,
    }),
  });
  console.log(input);
  await showDialog(input);
}

export async function showMergeWorkSheetsDialog(): Promise<void> {
  let workbook = await excelService.listStructure();
  let input: DialogInput<MergeWorkSheetsInput> = new DialogInput<MergeWorkSheetsInput>({
    name: AppConsts.MergeWorkSheetsDialog,
    body: new MergeWorkSheetsInput({
      workbooks: [workbook],
    }),
  });
  await showDialog(input);
}
export async function showClearFormatDialog(): Promise<void> {
  let workbook = await excelService.listStructure();
  let input: DialogInput<ClearFormatInput> = new DialogInput<ClearFormatInput>({
    name: AppConsts.ClearFormatDialog,
    body: new ClearFormatInput({
      workbooks: [workbook],
    }),
  });
  await showDialog(input);
}
export async function showErrorDialog(error: string): Promise<void> {
  let errorInput: DialogInput<string> = new DialogInput<string>({
    name: AppConsts.ErrorDialog,
    width: 30,
    height: 20,
    body: error,
  });
  await showDialog(errorInput);
}
export async function showDialog(dialogInput: DialogInput<any>): Promise<void> {
  CommonService.log(JSON.stringify(dialogInput));
  Office.context.ui.displayDialogAsync(
    `${AppConsts.appBaseUrl}/dialog.html`,
    { height: dialogInput.height, width: dialogInput.width },
    function (asyncResult: Office.AsyncResult<Office.Dialog>): void {
      dialog = asyncResult.value;
      dialog.addEventHandler(Office.EventType.DialogMessageReceived, dialogMessageFromChild);
      dialog.addEventHandler(Office.EventType.DialogEventReceived, dialogEventFromChild);
      setTimeout(function (): void {
        dialog.messageChild(JSON.stringify(dialogInput));
      }, 2000);
    }
  );
}

async function dialogMessageFromChild(arg: DialogEventArg): Promise<void> {
  CommonService.log(JSON.stringify(arg));
  let dialogOutput: DialogOutput<any> = JSON.parse(arg.message) as DialogOutput<any>;
  switch (dialogOutput.name) {
    case AppConsts.ClearFormatDialog:
      await clearFormatProcess(dialogOutput.body);
      break;
    case AppConsts.MergeWorkSheetsDialog:
      await MergeWorkSheetsProcess(dialogOutput.body);
      break;
    case AppConsts.TocDialog:
      await tocProcess(dialogOutput.body);
      break;
    case AppConsts.MoveColumnDialog:
      await moveColumnProcess(dialogOutput.body);
      await Excel.run(async (context) => {
        const sheet: Excel.Worksheet = context.workbook.worksheets.getActiveWorksheet();
        var headerRange: Excel.Range = sheet.getUsedRange().getRow(0);
        headerRange.load("values");
        await context.sync();
        console.log(headerRange.values);
        let headerValues: any[] = headerRange.values[0];
        let moveColumnInput: MovelColumnInput = new MovelColumnInput({
          columnNames: headerValues,
        });
        let input: DialogInput<MovelColumnInput> = new DialogInput<MovelColumnInput>({
          name: AppConsts.MoveColumnDialog,
          width: 50,
          height: 50,
          body: moveColumnInput,
        });
        dialog.messageChild(JSON.stringify(input));
      });
      break;
    default:
      document.getElementById(AppConsts.MessageDialog).innerHTML = "Hello Meekou";
      dialog.close();
      break;
  }
}
async function MergeWorkSheetsProcess(output: MergeWorkSheetsOutput): Promise<void> {
  await excelService.mergeWorkSheets(output.selectedWorkSheets);
}
async function tocProcess(output: TocOutput): Promise<void> {
  await excelService.activeSheet(output.selectedSheetName);
}
async function moveColumnProcess(output: MoveColumnOutput): Promise<void> {
  await excelService.moveColumn(output.startIndex, output.endIndex);
}
async function clearFormatProcess(output: ClearFormatOutput): Promise<void> {
  await excelService.clearFormat(output);
}
function dialogEventFromChild(arg: DialogEventArg): void {
  switch (arg.error) {
    case 12002:
      CommonService.log(
        "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      CommonService.log("The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.");
      break;
    case 12006:
      CommonService.log("Dialog closed.");
      break;
    default:
      CommonService.log("Unknown error in dialog box.");
      break;
  }
}
